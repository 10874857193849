//Packages
import axios from "axios";
import React, { useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import { CustomDialog } from "react-st-modal";
import { toast } from "react-toastify";

//Helper Files
import reqHeader from "../../helper/reqHeader.js";

//Styles
import "../../../node_modules/react-h5-audio-player/lib/styles.css";

const DownloadSourceFiles = (props) => {
  const {
    projectID,
    custID,
    srctgtlang,
    projectName,
    language: srcLang,
    fileDuration: totalDuration,
    fileLocation: url,
    targetFileLocation: targetUrl,
    targetFileName,
    targetOutputFormat,
    serviceType,
    targetJsonFileName,
    targetJsonFileLocation: targetJsonFileUrl,
  } = props.selectedRow;
  //const {customer}=props;
  
  //console.log("props", props);
  console.log("props.selectedRow", props.selectedRow);
  
  const [urlPath, setUrlPath] = useState("");
  const [urlDateTime, setUrlDateTime] = useState(new Date());
  const [fileLocationUrl, setFileLocationUrl] = useState("");
  const [targetFileLocationUrl, setTargetFileLocationUrl] = useState("")
  const [expiryDateTime, setExpiryDateTime] = useState(new Date());

  useEffect(()=>{
    async function getfileLocation(){
      const getUrl = `${process.env.REACT_APP_URL}/admin/upload/getProjectFiles`;
      const headers = reqHeader(true, true, true);

      const params = {
        projectID: props.selectedRow.projectID,
        projectName: props.selectedRow.projectName,
        serviceType: props.selectedRow.serviceType,
      };

      const config = { headers, params };

      //console.log("params", params)

      await axios.get(getUrl, config).then((response)=>{
        //console.log("response", response.data.data)
        let url = response.data.data;
          url.map((urls)=>{ 
            setFileLocationUrl(urls.fileLocation);
            setTargetFileLocationUrl(urls.targetFileLocation);
          })
      })

    }
    getfileLocation();
  },[])

  // useEffect(() => {
  //   async function getSignedURL() {
  //     const downloadurl = `${process.env.REACT_APP_URL}/admin/upload/downloadFiles`;
  //     const headers = reqHeader(true, true, true);
  //     var revisedUrl =
  //       process.env.REACT_APP_ENVIRONMENT === "production"
  //         ? url.replace("/texlang-cloud-storage/", "/")
  //         : url.replace("/staging-texlang-cloudstorage/", "/");

  //     const params = {
  //       fpath: revisedUrl,
  //       type: serviceType,
  //     };

  //     const config = { headers, params };

  //     await axios
  //       .get(downloadurl, config)
  //       .then((response) => {
  //         const { url } = response.data.data;

  //         setUrlPath(url);

  //         let googDate = url.split("&")[2];
  //         let googExpiry = url.split("&")[3];

  //         if (googDate !== undefined && googExpiry !== undefined) {
  //           let date = new Date(
  //             parseInt(googDate.substring(12, 16)),
  //             parseInt(googDate.substring(16, 18)),
  //             parseInt(googDate.substring(18, 20)),
  //             parseInt(googDate.substring(21, 23)),
  //             parseInt(googDate.substring(23, 25)),
  //             parseInt(googDate.substring(25, 27)),
  //             0
  //           );

  //           let expiry = parseInt(googExpiry.substring(15, googExpiry.length));

  //           setUrlDateTime(date);
  //           setExpiryDateTime(date.setSeconds(date.getSeconds() + expiry));
  //         }
  //       })
  //       .catch((err) => {});
  //   }
  //   getSignedURL();
  // }, [url, serviceType]);

  // const handleSrcDownload = async () => {

  //     const headers = reqHeader(true, true, true);

  //     let downloadUrl = `${process.env.REACT_APP_URL}/admin/upload/downloadFiles`;

  //     var revisedUrl =
  //       process.env.REACT_APP_ENVIRONMENT === "production"
  //         ? url.replace("/texlang-cloud-storage/", "/")
  //         : url.replace("/staging-texlang-cloudstorage/", "/");

  //     const params = {
  //       fpath: revisedUrl,
  //       type: serviceType,
  //     };

  //     let fname = targetUrl.split("/")[7];

  //     const config = { headers, params };
  //     await axios
  //       .get(downloadUrl, config)
  //       .then((response) => {
  //         const { url } = response.data.data;

  //         if (url !== undefined) {
  //           const link = document.createElement("a");
  //           link.download = fname;
  //           link.target = "_blank";
  //           link.href = url;
  //           document.body.appendChild(link);
  //           link.click();
  //         } else {
  //           toast.error(response.data.msg);
  //         }
  //       })
  //       .catch((err) => {});
  // };

  // const handleJsonDownload = async () => {
  //   const downloadurl = `${process.env.REACT_APP_URL}/admin/upload/downloadFiles`;
  //   const headers = reqHeader(true, true, true);
  //   var revisedUrl =
  //      process.env.REACT_APP_ENVIRONMENT === "production"
  //         ? jsonFileLocationUrl.replace("/texlang-cloud-storage/", "/")
  //         : jsonFileLocationUrl.replace("/staging-texlang-cloudstorage/", "/")
       

  //   const params = {
  //     fpath: revisedUrl,
  //     type: "Transcription",
  //   };

  //   const config = { headers, params };

  //   let fname = revisedUrl.split("/")[7];

  //   console.log("fname, config", fname, config)

  //   await axios
  //     .get(downloadurl, config)
  //     .then((response) => {
  //       const { url } = response.data.data;

  //       // const link = document.createElement("a");
  //       // link.download = fname;
  //       // link.target = "_blank";
  //       // link.href = url;
  //       // document.body.appendChild(link);
  //       // link.click();

  //       var zip_file_path = url //put inside "" your path with file.zip
  //       var zip_file_name = "download" //put inside "" file name or something
  //       var a = document.createElement("a");
  //       document.body.appendChild(a);
  //       a.style = "display: none";
  //       a.href = zip_file_path;
  //       a.download = zip_file_name;
  //       a.click();
  //       document.body.removeChild(a);
  //     })
  //     .catch((err) => {});
  // };
  const handleSRCFileDownloadLink = async () => {
    let revisedUrl =
       process.env.REACT_APP_ENVIRONMENT === "production"
          ? fileLocationUrl.replace("/texlang-cloud-storage/", "/")
          : fileLocationUrl.replace("/staging-texlang-cloudstorage/", "/")
        console.log("revisedUrl", revisedUrl)

     let Url = revisedUrl.split("/");
     console.log("completeUrl", Url)

    let getFinalUrl = `gsutil -m cp gs://${Url[3]}/${Url[4]}/${Url[5]}/${Url[6]}/${Url[7]}/* .`
    console.log("getFinalUrl", getFinalUrl)
     const dummy = document.createElement('p');
     dummy.textContent = getFinalUrl;
     document.body.appendChild(dummy);
   
     const range = document.createRange();
     range.setStartBefore(dummy);
     range.setEndAfter(dummy);
   
     const selection = window.getSelection();
     // First clear, in case the user already selected some other text
     selection.removeAllRanges();
     selection.addRange(range);
   
     document.execCommand('copy');
     document.body.removeChild(dummy);

    toast.success("Link Copied")

  }

  const handleTargetFileDownloadLink = async () => {
    let revisedUrl =
       process.env.REACT_APP_ENVIRONMENT === "production"
          ? targetFileLocationUrl.replace("/texlang-cloud-storage/", "/")
          : targetFileLocationUrl.replace("/staging-texlang-cloudstorage/", "/")

    let Url = revisedUrl.split("/");

     let getFinalUrl = `gsutil -m cp gs://${Url[3]}/${Url[4]}/${Url[5]}/${Url[6]}/${Url[7]}/* .`
     const dummy = document.createElement('p');
     dummy.textContent = getFinalUrl;
     document.body.appendChild(dummy);
   
     const range = document.createRange();
     range.setStartBefore(dummy);
     range.setEndAfter(dummy);
   
     const selection = window.getSelection();
     // First clear, in case the user already selected some other text
     selection.removeAllRanges();
     selection.addRange(range);
   
     document.execCommand('copy');
     document.body.removeChild(dummy);

    toast.success("Link Copied")

  }
  const handleTgtExcelFile = async (serviceType) => {};

  return (
    <>
      <div className="contentLeft">
        <div className="block">
          <div className="addProjectDetails">
            {/* <div className="d-flex createOption">
              <AudioPlayer
                src={`${urlPath}`}
                controls={true}
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                className="audioPlayer"
                style={{
                  background: "#740AF6",
                  padding: "40px",
                  borderRadius: "5px",
                }}
              />
            </div> */}

            <div className="d-flex createOption">
              <div className="inputWrap">
                <label className="label">Project Name</label>
                <input
                  type="text"
                  placeholder="File Name"
                  name="fileName"
                  readOnly={true}
                  value={projectName}
                  title={projectName}
                />
              </div>

              <div className="inputWrap">
                <label className="label">Duration</label>
                <input
                  type="text"
                  placeholder="Duration"
                  name="duration"
                  readOnly={true}
                  value={totalDuration}
                  title={totalDuration}
                />
              </div>

              <div className="inputWrap">
                <label className="label">Source Language</label>
                <input
                  type="text"
                  placeholder="Language"
                  name="language"
                  readOnly={true}
                  value={srcLang}
                  title={srcLang}
                />
              </div>
            </div>

            <div className="d-flex createOption">
              <div className="inputWrap">
                <label className="label">Target File Name</label>
                <input
                  type="text"
                  placeholder="Target File Name"
                  name="targetfileName"
                  readOnly={true}
                  value={
                    targetFileName === "" ? targetJsonFileName : targetFileName
                  }
                  title={
                    targetFileName === "" ? targetJsonFileName : targetFileName
                  }
                />
              </div>

              <div className="inputWrap">
                <label className="label">Output Format</label>
                <input
                  type="text"
                  placeholder="Output Format"
                  name="output"
                  readOnly={true}
                  value={targetOutputFormat}
                  title={targetOutputFormat}
                />
              </div>

              <div className="inputWrap">
                <label className="label">Target Language</label>
                <input
                  type="text"
                  placeholder="Target Language"
                  name="targetlanguage"
                  readOnly={true}
                  value={srcLang}
                  title={srcLang}
                />
              </div>
            </div>

            <div className="d-flex createOption">
              <div className="inputWrap">
                <button
                  className="fileDownloadBtn"
                  onClick={() => handleSRCFileDownloadLink()}
                >
                  Source File Download Link
                </button>
              </div>

              <div className="inputWrap">
                <button
                  className="fileDownloadBtn"
                  disabled={targetUrl === "" ? true : false}
                  onClick={() => handleTargetFileDownloadLink("target")}
                >
                  Target File
                </button>
              </div>

              {/* <div className="inputWrap">
                <button
                  className="fileDownloadBtn"
                  disabled={targetJsonFileUrl === "" ? true : false}
                  onClick={() => handleJsonDownloadLink()}
                >
                  Json Download Link
                </button>
              </div> */}

              {/* <div className="inputWrap">
                <button
                  className="fileDownloadBtn"
                  disabled={targetUrl === "" ? true : false}
                  onClick={() => handleTgtExcelFile("Transcription")}
                >
                  Excel File
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadSourceFiles;
