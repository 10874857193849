import axios from "axios";
import React, { useEffect, useMemo, useState, useRef } from "react";
import "../../../src/styles.css";
import reqHeader from "../../helper/reqHeader.js";
import ProgressBar from "../Utils/ProgressBar.jsx";
import Table from "../Table/Table.jsx";
import UploadLangDomain from "../Uploads/UploadLangDomain.jsx";
import UploadTranslationTGTFiles from "../Uploads/UploadTranslationTGTFiles.jsx";
import UploadTranslationSRCFiles from "../Uploads/UploadTranslationSRCFiles.jsx";
import { CustomDialog } from "react-st-modal";
import moment from "moment";
import {
  DownloadIcon,
  UploadIcon,
} from "../../svgIconComponent/svg_icon_components";
import DownloadSourceFiles from "../Downloads/DownloadSourceFiles"

const SrcTgtLangList = (props) => {
  const {
    type,
    customer,
    setClickedSrcTgtLang,
    clickedSrcTgtLang,
    langDomainDuration,
    totalDuration,
    progress,
    status,
  } = props;
  //console.log("customer..",customer);
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectedRowsValues, setSelectedRowsValues] = useState({});
  const [custproject, setCustProject] = useState({});
  const [custtype,setCusttype]= useState();

  //console.log("customer", customer);
  //console.log("clickedSrcTgtLang",clickedSrcTgtLang);
  

  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "srctgtlang",
        sortType: "basic",
        filter: "text",
        sticky: "left",
        Cell: ({ value, column, row }) => {
          return (
            <div
              title="Click for the detailed File List"
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original)}
            >
              {row.original.srctgtlang}
            </div>
          );
        },
      },
      {
        Header: "Closure",
        accessor: "percentageOfCompletion",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick(row.original)}
            >
              <ProgressBar completed={row.original.percentageOfCompletion} />
            </div>
          );
        },
      },
      // {
      //   Header: "Progress / Total",
      //   accessor: "completedDuration",
      //   sortType: "basic",
      //   filter: "text",
      //   hidden: !langDomainDuration,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div
      //         title="Click for the detailed File List"
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original)}
      //       >
      //         {type === "track"
      //           ? `${row.original.completedDuration} / ${row.original.uploadedDuration}`
      //           : type === "assign"
      //           ? `${row.original.assignedDuration} / ${row.original.uploadedDuration}`
      //           : `${row.original.uploadedDuration} / ${row.original.duration} `}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   sortType: "basic",
      //   filter: "text",
      //   hidden: !status,
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div
      //         title="Click for the detailed File List"
      //         style={{ cursor: "pointer" }}
      //         onClick={() => handleClick(row.original)}
      //       >
      //         {row.original.status}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: "Upload TGT",
        accessor: "upload",
        sortType: "basic",
        filter: "text",
        hidden: (type === "updown") ? false : true,
        Cell: ({ value, column, row }) => {
          //console.log("table row",row);
          return (
            <button
              style={{ cursor: "pointer" }}
              title="Click for the detailed File List"
              className="uploadBtb"
              onClick={() => handleTGTUpload(row.original)}
              disabled={row.original.status !== "Completed" ? false : true}
            >
              <UploadIcon />
              &nbsp; Upload
            </button>
          );
        },
      },
      {
        Header: "Upload SRC",
        accessor: "uploadsrc",
        sortType: "basic",
        filter: "text",
        hidden: (type === "updown" ) ? false : true,
        // && customer.custType=="OC" EC updown
        Cell: ({ value, column, row }) => {
          //console.log("table row",row);
          return (
            <button
              style={{ cursor: "pointer" }}
              title="Click for the detailed File List"
              className="uploadBtb"
              onClick={() => handleSRCUpload(row.original)}
              disabled={(row.original.status !== "Completed" && row.original.custType==="EC") ? false : true}
            >
              <UploadIcon />
              &nbsp; Upload
            </button>
          );
        },
      },
      {
        Header: "Download",
        accessor: "download",
        sortType: "basic",
        filter: "text",
        hidden: type === "updown" ? false : true,
        Cell: ({ value, column, row }) => {
          //console.log("row", row)
          return (
            <button
              className="downloadBtn"
              title="Click for the detailed File List"
              onClick={() => handleDownload(row.original,customer)}
            >
              <DownloadIcon />
              &nbsp; Download
            </button>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    const getLanguageDomains = async () => {
      const url = `${process.env.REACT_APP_URL}/admin/translation/projectsrctgtlanglist`;
      const headers = reqHeader(true, true, true);
      const params = {
        customerID: customer.customerID,
        projectID: customer._id,
        custType:customer.custType
      };
      console.log("params..",params);
      const config = { headers, params };

      await axios
        .get(url, config)
        .then((res) => {
          const { data } = res.data;
          //console.log("src tgt lang data", data)
        // let uniqueSrcTgtLanglist = [
        //   ...new Map(data.map((item) => [JSON.stringify(item.srctgtlang), item.srctgtlang,item.percentageOfCompletion])).values(),
        // ];

        // console.log("uniqueSrcTgtLanglist",uniqueSrcTgtLanglist);
  
        // const uniquedata = Object.assign({}, ...uniqueSrcTgtLanglist);
        // console.log("uniquedata..",uniquedata);
        let newarray=[];
        data.map((row)=>{
          let obj={
            projectID:customer._id,
            custID:customer.customerID,
            custEmail:customer.custEmail,
            custName:customer.custName,
            ETA:customer.ETA,            
            projectName: customer.projectName,
            srctgtlang: row.srctgtlang,
            percentageOfCompletion:row.percentageOfCompletion,
            serviceType:"Translation",
            folderPath:customer.folderPath,
            custType:customer.custType
          }
          newarray.push(obj);
        });
          //console.log("newarray",newarray);
          let uniquedata = newarray.filter(function (a) {
                var key = a.srctgtlang + '|' + a.percentageOfCompletion;
                if (!this[key]) {
                    this[key] = true;
                    return true;
                }
            }, Object.create(null));
          //console.log("uniquedata..",uniquedata);

          setData(uniquedata);
          setCusttype(customer.custType);
        })
        .catch((err) => {
          setData([]);
        });
    };

    if (customer && Object.keys(customer).length !== 0) {
      getLanguageDomains();
    }
  }, [customer]);

  const handleClick = (row) => {
    //console.log("row..",row);
    //console.log("customer..",customer);
    if (row !== undefined) {
      setClickedSrcTgtLang(row);
    } else {
      setClickedSrcTgtLang({});
    }
  };

  const handleTGTUpload = async (row) => {
    // console.log("row..",row);
    // let upldDetails = {
    //   domainID: row._id,
    //   id: row.projectID,
    //   projectName: row.projectName,
    //   customerID: row.customerID,
    //   custName: row.custName,
    //   custEmail: row.custEmail,
    //   custType: row.custType,
    //   custCode: row.custCode,
    //   srcLang: row.language,
    //   tgtFormat: row.language,
    //   isToolNeeded: row.isToolNeeded,
    //   ETA: row.ETA,
    //   // domains: [
    //   //   {
    //   //     domainID: row._id,
    //   //     language: row.language,
    //   //     domain: row.domain,
    //   //     frequency: row.frequency,
    //   //     duration: row.duration,
    //   //     upDuration: row.uploadedDuration,
    //   //     folderPath: row.folderPath,
    //   //   },
    //   // ],
    //   serviceType: "Translation",
    //   tgtFormat: row.language,
    // };

    await CustomDialog(
      <UploadTranslationTGTFiles
        selectedRow={row}
        serviceType={"Translation"}
        src="Target"
      />,
      {
        title: "Upload Translation Target Files",
        showCloseIcon: false,
      }
    );
  };

  const handleSRCUpload = async (row) => {
    console.log("customer.custType...",custtype);
    //console.log("row..",row);
    
    await CustomDialog(
      <UploadTranslationSRCFiles
        selectedRow={row}
        serviceType={"Translation"}
        src="Source"
      />,
      {
        title: "Upload Translation Source Files",
        showCloseIcon: false,
      }
    );
       
  };

  const handleDownload = async (row) => {
    //console.log("row on download click",row)
    await CustomDialog(<DownloadSourceFiles selectedRow={row} />, {
      title: "File Downloads",
      showCloseIcon: true,
    });
  };

  return (
    <div className="tableContainer">
      <div className="tableTopHeader">
        <button className="table-btn">New</button>
        <button className="table-btn">Close</button>
      </div>
      {/* {type === "updown" && clickedSrcTgtLang && clickedSrcTgtLang.srctgtlang ?(
        <div>
           <button
              className="downloadBtn"
              title="Click to download Source Files"
              onClick={() => handleDownload(data)}
            >
              <DownloadIcon />
              &nbsp; Download
            </button>
        </div>
      )
      :(<div></div>)
      } */}
      <Table
        columns={columns}
        data={data}
        count={data.length}
        pagination={true}
        expanded={false}
        hideTargetDownload={false}
        hideInvoiceDownload={false}
        hideReceiptDownload={false}
        isHeader={true}
        filter={true}
        isCountDisplayed={true}
        customText={customer.projectName}
        //customer={customer}
        setClickedSrcTgtLang={setClickedSrcTgtLang}
        //setCustProject={setCustProject}
        isMultiSelect={false}
        setSelectedRows={setSelectedRows}
        setSelectedRowsValues={setSelectedRowsValues}
      />
    </div>
  );
};

export default SrcTgtLangList;
