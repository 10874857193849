import React, { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logo from "../../assets/images/Logo.svg";
import "../../../node_modules/react-simple-tree-menu/dist/main.css";
import {
  MenuArrowRight,
  SignOut,
} from "../../svgIconComponent/svg_icon_components";
import { logout } from "../../actions/userActions.js";

const LeftMenuList = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const logoutHandler = () => {
    dispatch(logout());
  };

  const treeData = useMemo(
    () => [
      {
        key: "000",
        label: "Home",
        nodes: [],
        url: "/dashboard",
        activeKey: "000",
        activeNodes: ["000"],
      },
      {
        key: "001",
        label: "Entities",
        nodes: [
          {
            key: "001_001",
            label: "User",
            nodes: [],
            url: "/users",
            activeKey: "001/001_001",
            activeNodes: ["001", "001_001"],
          },
          {
            key: "001_002",
            label: "Customer",
            nodes: [],
            url: "/customers",
            activeKey: "001/001_002",
            activeNodes: ["001", "001_002"],
          },
          {
            key: "001_003",
            label: "Vendors",
            nodes: [],
            url: "/vendors",
            activeKey: "001/001_003",
            activeNodes: ["001", "001_003"],
          },
          {
            key: "001_004",
            label: "Category, Languages And Rates",
            nodes: [],
            url: "/languagesrates",
            activeKey: "001/001_004",
            activeNodes: ["001", "001_004"],
          },
          {
            key: "001_005",
            label: "Domain, Priorities & Deadlines",
            nodes: [],
            url: "/prioritiesdeadlines",
            activeKey: "001/001_005",
            activeNodes: ["001", "001_005"],
          },
          {
            key: "001_006",
            label: "Translation Vendors",
            nodes: [],
            url: "/translationvendors",
            activeKey: "001/001_006",
            activeNodes: ["001", "001_006"],
          },
        ],
        activeKey: "001",
        activeNodes: ["001"],
      },
      {
        key: "002",
        label: "Transcriptions",
        nodes: [
          {
            key: "002_001",
            label: "Assign",
            nodes: [],
            url: "/transcriptionassign",
            activeKey: "002/002_001",
            activeNodes: ["002", "002_001"],
          },
          {
            key: "002_002",
            label: "Upload / Download",
            nodes: [],
            url: "/transcriptionupdown",
            activeKey: "002/002_002",
            activeNodes: ["002", "002_002"],
          },
          {
            key: "002_003",
            label: "Tracking",
            nodes: [],
            url: "/transcriptiontrack",
            activeKey: "002/002_003",
            activeNodes: ["002", "002_003"],
          },
          {
            key: "002_004",
            label: "Delivery",
            nodes: [],
            url: "/transcriptiondelivery",
            activeKey: "002/002_004",
            activeNodes: ["002", "002_004"],
          },
          {
            key: "002_005",
            label: "Invoices / Payments",
            nodes: [],
            url: "/transcriptioninvpay",
            activeKey: "002/002_005",
            activeNodes: ["002", "002_005"],
          },
        ],
        // url: "/transcriptions",
        activeKey: "002",
        activeNodes: ["002"],
      },
      {
        key: "003",
        label: "Translations",
        nodes: [
          {
            key: "003_001",
            label: "Assign",
            nodes: [],
            url: "/translationassign",
            activeKey: "003/003_001",
            activeNodes: ["003", "003_001"],
          },
          {
            key: "003_002",
            label: "Upload / Download",
            nodes: [],
            url: "/translationUpDown",
            activeKey: "003/003_002",
            activeNodes: ["003", "003_002"],
          },
          // {
          //   key: "003_003",
          //   label: "Tracking & Delivery",
          //   nodes: [],
          //   url: "/translationTrack",
          //   activeKey: "003/003_003",
          //   activeNodes: ["003", "003_003"],
          // },
          // {
          //   key: "003_004",
          //   label: "Invoices / Payments",
          //   nodes: [],
          //   url: "/translationInvPay",
          //   activeKey: "003/003_004",
          //   activeNodes: ["003", "003_004"],
          // },
        ],
        //url: "/translations",
        activeKey: "003",
        activeNodes: ["003"],
      },
      {
        key: "004",
        label: "Subtitlings",
        nodes: [
          {
            key: "004_001",
            label: "Assign",
            nodes: [],
            url: "/subtitlingAssign",
            activeKey: "004/004_001",
            activeNodes: ["004", "004_001"],
          },
          {
            key: "004_002",
            label: "Upload / Download",
            nodes: [],
            url: "/subtitlingUpDown",
            activeKey: "004/004_002",
            activeNodes: ["004", "004_002"],
          },
          {
            key: "004_003",
            label: "Tracking & Delivery",
            nodes: [],
            url: "/subtitlingTrack",
            activeKey: "004/004_003",
            activeNodes: ["004", "004_003"],
          },
          {
            key: "004_004",
            label: "Invoices / Payments",
            nodes: [],
            url: "/subtitlingInvPay",
            activeKey: "004/004_004",
            activeNodes: ["004", "004_004"],
          },
        ],
        url: "/subtitlings",
        activeKey: "004",
        activeNodes: ["004"],
      },
    ],
    []
  );

  const [selectedIndex, setSelectedIndex] = useState(0);

  const location = useLocation();

  const handleClickEvent = (treeElement, index) => {
    history.push(treeElement.url);
    setSelectedIndex(index);
  };

  return (
    <>
      <div className="leftSideBar">
        <div>
          <img src={Logo} alt="Logo" className="LHSLogo" />
          <ul className="navigationWrap">
            {treeData.map((treeElement, index) => (
              <li className="navItem" key={treeElement}>
                <div
                  className="navLabel"
                  onClick={() => handleClickEvent(treeElement, index)}
                >
                  {treeElement.label}
                </div>

                {treeElement.nodes.length !== 0 ? (
                  selectedIndex === index && (
                    <ul className="subMenu">
                      {treeElement.nodes.map((nodeItems) => (
                        <li
                          className="subMenuItem"
                          key={nodeItems}
                          style={
                            location.pathname === nodeItems.url
                              ? { fontWeight: "bold" }
                              : {}
                          }
                          onClick={() => history.replace(nodeItems.url)}
                        >
                          <MenuArrowRight />
                          <span>{nodeItems.label}</span>
                        </li>
                      ))}
                    </ul>
                  )
                ) : (
                  <div />
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="logoutLink" onClick={logoutHandler}>
          <SignOut />
          <div className="navLabel">Sign Out</div>
        </div>
      </div>
    </>
  );
};

export default LeftMenuList;
