import axios from "axios";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import Select from "react-select";
import { useDialog, ModalContent, ModalFooter, ModalButton } from "react-st-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../../helper/reqHeader.js";
import Spinner from "../Utils/Spinner.jsx";
import { CSVLink } from "react-csv";

const UploadTranslationSRCFiles = (props) => {
    console.log("props in UploadTranslationTGTFiles..",props);
  const {
    custID:customerID,
    percentageOfCompletion,
    projectID,
    ETA,
    projectName,
    srctgtlang,
    custName,
    custEmail,
    custType,
    folderPath
  } = props.selectedRow;
  
  const { serviceType,src} = props;
  const srclang=srctgtlang.split("_")[0];
  const tgtlang=srctgtlang.split("_")[1];
  console.log("sourcelang,tgtlang",srclang,tgtlang);

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [spinnerMessage, setSpinnerMessage] = useState("");
  const [upload, setUpload] = useState(false);

  //const [languages, setLanguages] = useState("");
  //const [language, setLanguage] = useState();
  //const [domains, setDomains] = useState("");
  //const [domain, setDomain] = useState();
  //const [frequencies, setFrequencies] = useState("");
  //const [frequency, setFrequency] = useState();
  //const [duration, setDuration] = useState("00:00:00");
  //const [upDuration, setUpDuration] = useState("00:00:00");
  const [fileList, setFileList] = useState([]);
  //const [folderPath, setFolderPath] = useState("");
  const [csvData, setcsvData] = useState([]);

  // use this hook to control the dialog
  const dialog = useDialog();

  toast.configure();

  const acceptExtensions = "text/*,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf";
  //const readOnly = src === "LangDomain" ? true : false;

  const handleSubmit = async (files, allFiles) => {

    console.log("files, allFiles",files, allFiles);
    allFiles.forEach((f) => f.remove());

    let file = files.map((f) => f.file);
    let meta = files.map((f) => f.meta);
    console.log("srclang,tgtlang,projectID",srclang,tgtlang,projectID);
    if (
      srclang !== undefined &&
      srclang !== null &&
      tgtlang !== undefined &&
      tgtlang !== null &&
      projectID !== undefined &&
      projectID !== null
    ) {
      setSpinnerMessage("Uploading files... Please Wait!");
      setLoading(true);

      let existedFiles = [];

      for (let newFile of file) {
        let exists = fileList.indexOf(newFile.name);

        if (exists === 0) {
          existedFiles.push(newFile.name);
        }
      }

      if (existedFiles.length > 0) {
        toast.error(
          <div>
            The below files are already uploaded :-
            <br />
            {existedFiles.map((value) => {
              return (
                <span key={value}>
                  {value} <br />
                </span>
              );
            })}
          </div>
        );

        setLoading(false);
        setUpload(!upload);

        return;
      } else {
        await fileTextList(file, meta);

        //await uploadDuration();
        
        setLoading(false);
        setUpload(!upload);
        //dialog.close(window.location.reload());
      }
    } else {
      if (srclang === undefined || srclang === null) {
        toast.error("Source Language not found!");
      } else if (tgtlang === undefined || tgtlang === null) {
        toast.error("Target Language not found!!");
      } else if (projectID === undefined || projectID === null) {
        toast.error("Project ID not found!");
      }
    }
  };

  const fileTextList = async (files, metas) => {
    
    let counter = 0;
    let failedFileList = [];
    for (let file of files) {
      try {
          const url = `${process.env.REACT_APP_URL}/admin/upload/uploadtranslationprojectfiles`;
          const headers = reqHeader(true, true, true);

          const config = { headers };

          const body = {
            projectID: projectID,
            projectName: projectName,
            customerID: customerID,
            custName:custName,
            custEmail: custEmail,
            ETA:ETA,
            name: file.name,
            srcLang: srctgtlang.split("_")[0],
            tgtLang: srctgtlang.split("_")[1],
            serviceType: serviceType,
            source: src,
            folderPath: folderPath,
            custType: custType,
          };
          console.log("body", body);
          const { data } = await axios.post(url, body, config);
          console.log("data", data);
          if (data.msg == "Upload success" && data.success == true) {
            const signedurl = data.data.url;
            const urls = data.data.srcuploadpath;

            await sendFile(signedurl, files[counter], urls);

          }else{
            failedFileList.push(file.name);
            console.log("file name", failedFileList)
          }
        
        
      } catch (error) {}

      counter = counter + 1;
      console.log("Counter", counter)
    }

    if(failedFileList.length > 0){
      //console.log("file name", failedFileList);
      let getcsvdata =[]
      failedFileList.map((data)=>{
        let obj = {"fileName":data}
        getcsvdata.push(obj)
        setcsvData(getcsvdata)
      });
      toast.error(`Something went wrong, Please check failed file list `);
    }
    else{
      toast.success(`File Uploaded Successfully !!!`);
    }
    // if(csvData && csvData.length > 0){
    //   toast.error(`Something went wrong, Please check failed file list `);
    //   //setcsvData([]);
    // }else{
    //   toast.success(`File Uploaded Successfully !!!`);
    // }
    return counter;
  };
 // console.log("csvData", csvData);

  const sendFile = (signedUrl, file, url) => {
    console.log("file in sendFile..",file);
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(signedUrl);
          } else {
            reject({
              status: xhr.status,
              statusText: xhr.statusText,
            });
          }
        }
      };

      xhr.upload.onprogress = (evt) => {
        // For uploads
        if (evt.lengthComputable) {
          let percentComplete = ((evt.loaded / evt.total) * 100).toFixed(2);
          setProgress(percentComplete);
          console.log("progress uploading ", percentComplete);
        }
      };

      xhr.onloadend = async function () {
        // setLoading(false);
        // toast.success(`File Uploaded Successfully !!!`);
        // setTimeout(() => {
        //   dialog.close(url);
        //   window.location.reload();
        // }, 2000);
      };

      xhr.open("PUT", signedUrl);
      xhr.setRequestHeader("Content-type", "text/plain; charset=utf-8");
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.send(file);

      ///update file wordcount in db
      const updateWordCount = async () => {
          const url = `${process.env.REACT_APP_URL}/admin/upload/updatefilewordcount`;
          const headers = reqHeader(true, true, true);
          const formData = new FormData();
          formData.append('file', file);
          formData.append('fileName', file.name);
          // const config = {
          //     headers: {
          //               'content-type': 'multipart/form-data',
          //               },
          // };

          const params = {
            projectID: projectID,
            // projectName: projectName,
            customerID: customerID,
            name: file.name,
            srcLang: srctgtlang.split("_")[0],
            tgtLang: srctgtlang.split("_")[1],
            // surl:signedUrl
          };

          //const body = {};

          const config = { headers,params };

          await axios
            .put(url, formData, config)
            .then((res) => {})
            .catch((err) => {});
        };
        updateWordCount();
      ///
    });
  };

  // const uploadDuration = async () => {
  //   const url = `${process.env.REACT_APP_URL}/admin/transcription/updateuploaddurations`;
  //   const headers = reqHeader(true, true, true);

  //   const params = {
  //     projectID: id,
  //     language: language.value,
  //     domain: domain.value,
  //     frequency: frequency.value,
  //     domainID,
  //   };

  //   const body = {};

  //   const config = { headers, params };

  //   await axios
  //     .put(url, body, config)
  //     .then((res) => {})
  //     .catch((err) => {});
  // };

  return loading ? (
      <Spinner spinneruploadtext={spinnerMessage} progress={progress} />
  ) : (
    <>
      <form action="#" encType="multipart/form-data">
        <div className="contentLeft">
          <div className="block">
          <ModalContent>
            <div className="addProjectDetails">
              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">
                    Project Name: <b>{projectName}</b><span className="requiredField"></span>
                  </label>
                </div>
              </div>
              <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label">
                    Source Language: <b>{srclang}</b><span className="requiredField"></span>
                  </label>
                </div>

                <div className="inputWrap">
                  <label className="label">
                    Target Language: <b>{tgtlang}</b><span className="requiredField"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="d-flex createOption">
                <div className="inputWrap">
                  <label className="label" style={{color: "red"}}>
                    Scanned Documents are not supported
                  </label>
                </div>
            </div>

            <Dropzone
              onSubmit={handleSubmit}
              autoUpload={false}
              // maxFiles={1}
              styles={{
                dropzone: {
                  overflow: "auto",
                  background: "#F1FAED",
                  border: "2px solid #78CF4D",
                },
                inputLabelWithFiles: { display: "none" },
                inputLabel: {
                  color: "#78CF4D",
                },
              }}
              canRemove={true}
              accept={acceptExtensions}
              inputContent={(files, extra) =>
                extra.reject
                  ? "Audio and Video files only"
                  : "Drag & Drop files here or click to select the file"
              }
            />
            {/* </div> */}
            </ModalContent>
            <ModalFooter>
              <ModalButton
                onClick={() => {
                  dialog.close(window.location.reload());
                  setcsvData([]);
                }}
              >
                Close
              </ModalButton>
                &nbsp;
                {console.log("csvData in html", csvData)}
              {csvData.length > 0 ? <CSVLink className="orangeBtn btn" data={csvData} target="_blank">
                Failed Filelist
              </CSVLink> : ""}
              
            </ModalFooter>
          </div>
        </div>
      </form>
    </>
  );
};

export default UploadTranslationSRCFiles;
